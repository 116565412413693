import { ajax } from "@lib/ajax";
import { Button, Table, Tag, Input, Select, Modal, Drawer, Divider, notification, Checkbox, Switch } from "antd";
import Axios from "axios";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import ReactJson from "react-json-view";
import ExcelDrawer from "./ExcelDrawer";
import { sessionStore } from "@app/stores/SessionStore";

const { Search, TextArea } = Input;
// const HOST = window.location.hostname === "localhost" ? "http://localhost:3007" : "https://translate-api.clopos.com";
const HOST = "https://translate-api.clopos.com";
const API = `${HOST}/api/v1/manage-translation`;
const API_Langs = `${HOST}/api/v1/get-languages`;
const API_Tags = `${HOST}/api/v1/get-tags`;
const API_URLs = `${HOST}/api/v1/get-urls`;
const API_Version = `${HOST}/api/v1/version-translation`;
const API_Import = `${HOST}/api/v1/import-translation`;

const dummyTRData = {
    _id: "",
    key: "",
    langs: "",
    tags: [],
    url: [],
    description: "",
};

const TranslatePage = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const [excelSubmitLoading, setExcelSubmitLoading] = useState<boolean>(false);
    const [deleteLoading, setDeleteLoading] = useState<boolean>(false);
    const [manageSubmitLoading, setManageSubmitLoading] = useState<boolean>(false);
    const [deletePhraseModal, setDeletePhraseModal] = useState<string>("");
    const [deleteModal, setDeleteModal] = useState<any>(null);
    const [importDrawer, setImportDrawer] = useState<any>(false);
    const [manageDrawer, setManageDrawer] = useState<any>(null);
    const [manageDrawerData, setManageDrawerData] = useState<any>(dummyTRData);
    const [langs, setLangs] = useState<any[]>([]);
    const [useAi, setUseAi] = useState<boolean>(true);
    const [selectedLangs, setSelectedLangs] = useState<string[]>(["en", "az"]);
    const [presetTags, setPresetTags] = useState<string[]>([]);
    const [presetURLs, setPresetURLs] = useState<string[]>([]);
    const [version, setVersion] = useState<string>("");
    const [selectedTags, setSelectedTags] = useState<string[]>([]);
    const [search, setSearch] = useState<string>("");
    const [data, setData] = useState<any[]>([]);
    const [pagination, setPagination] = useState<any>({
        current: 1,
        pageSize: 200,
        total: 0,
        limit: 100,
    });

    const columns = [
        {
            title: "Key",
            key: "key",
            render: (d: any) => (
                <div>
                    <b>{d.key}</b>
                    {d?.description ? (
                        <span style={{ fontSize: 11 }}>
                            <p>{d.description}</p>
                        </span>
                    ) : (
                        ""
                    )}
                </div>
            ),
        },
        {
            title: "Translates",
            dataIndex: "langs",
            key: "langs",
            render: (langs: any) => {
                let x = [];
                for (let l in langs) {
                    if (langs[l] && selectedLangs.indexOf(l) !== -1) {
                        x.push(
                            <p key={l}>
                                <b>{l.toUpperCase()}</b>: {langs[l]}
                            </p>,
                        );
                    }
                }
                return x.length !== 0 ? x : "-";
            },
        },
        {
            title: "URL(s)",
            dataIndex: "url",
            key: "url",
            render: (url: any) => {
                let x = [];
                for (let l in url) {
                    if (url[l]) {
                        x.push(
                            <a target={"_blank"} href={url[l]}>
                                <Tag color="blue" style={{ cursor: "pointer" }}>
                                    {url[l]}
                                </Tag>
                            </a>,
                        );
                    }
                }
                return x.length !== 0 ? x : "-";
            },
        },
        {
            title: "Tags",
            dataIndex: "tags",
            key: "tags",
            render: (tags: any) => {
                let x = [];
                for (let l in tags) {
                    if (tags[l]) {
                        const t: any = presetTags.find((pT: any) => pT?.key === tags[l]);
                        x.push(
                            <Tag key={l} color="purple">
                                {t?.name}
                            </Tag>,
                        );
                    }
                }
                return x.length !== 0 ? x : "-";
            },
        },
        {
            title: "Actions",
            render: (d: any) => {
                return (
                    <div style={{ display: "flex", flexDirection: "column" }}>
                        <Button
                            onClick={() => {
                                setManageDrawerData(d);
                                setManageDrawer(true);
                            }}
                            type="primary"
                            shape="circle"
                            icon="edit"
                        />
                        <Button onClick={() => setDeleteModal(d)} type="danger" shape="circle" icon="delete" />
                    </div>
                );
            },
        },
    ];

    const getTranslations = async (pagination: any = null, filters: any = null, sorter: any = null) => {
        setLoading(true);
        const res: any = await ajax
            .get({
                url: API,
                params: {
                    sortField: sorter?.field,
                    sortOrder: sorter?.order,
                    pagination: JSON.stringify(pagination),
                    search,
                    tags: selectedTags,
                    ...filters,
                },
            })
            .catch(() => setLoading(false));
        const d = res;
        if (d?.success) {
            setData(d.payload.docs);
            setPagination((prev: any) => ({
                ...prev,
                current: d.payload.page,
                total: d.payload.totalDocs,
            }));
        }
        setLoading(false);
    };

    const getLangs = async () => {
        const res: any = await ajax.get({ url: API_Langs, ignoreSuccess: true });
        // move en to the first
        const en = res?.payload.find((l: any) => l.name === "en");
        const langs = res?.payload.filter((l: any) => l.name !== "en");
        setLangs([en, ...langs]);
    };

    const getTags = async () => {
        const res: any = await ajax.get({ url: API_Tags, ignoreSuccess: true });
        setPresetTags(res?.payload || []);
    };

    const getURLs = async () => {
        const res: any = await ajax.get({ url: API_URLs, ignoreSuccess: true });
        setPresetURLs(res?.payload || []);
    };

    const getVersion = async () => {
        const res: any = await ajax.get({ url: API_Version, ignoreSuccess: true });
        setVersion(res?.version);
    };

    const deleteTranslation = async () => {
        if (confirm("Are you sure?") === true) {
            setDeleteLoading(true);
            const res: any = await ajax.delete({ url: API + "/" + deleteModal?._id, ignoreSuccess: true }).catch(() => {
                setDeleteLoading(false);
            });
            if (res?.success === true) {
                setDeleteModal(null);
                getTranslations(pagination);
            }
            setDeleteLoading(true);
        }
        setDeleteModal(null);
    };

    const submitManageData = async () => {
        setManageSubmitLoading(true);
        const getData = () => {
            const d = manageDrawerData?._id
                ? {
                      id: manageDrawerData?._id,
                      data: _.omit(manageDrawerData, ["_id", "__v", "score"]),
                  }
                : _.omit(manageDrawerData, ["_id", "__v", "score"]);
            d.useAi = useAi;
            return d;
        };
        const res = await ajax
            .load({
                url: API,
                method: manageDrawerData?._id ? "PUT" : "POST",
                data: getData(),
            })
            .catch(e => {
                setManageSubmitLoading(false);
                return e;
            });
        const hasError: any = _.get(res, "response.data.error[0]", null);
        if (hasError) {
            notification.error({
                message: hasError.message,
            });
        } else {
            setManageDrawer(false);
            setManageDrawerData(dummyTRData);

            await Promise.allSettled([getTranslations(pagination), getLangs(), getTags(), getURLs(), getVersion()]);
        }
        setManageSubmitLoading(false);
    };

    const submitExcelTranslations = async (excelData: any, language: any) => {
        setExcelSubmitLoading(true);
        const res = await ajax
            .post({
                url: API_Import,
                data: {
                    trans: excelData,
                    lang: language,
                },
                ignoreSuccess: true,
            })
            .catch(e => {
                setExcelSubmitLoading(false);
                return e;
            });
        const hasError: any = _.get(res, "response.data.error[0]", null);
        if (hasError) {
            notification.error({
                message: hasError.message,
            });
        } else {
            setImportDrawer(false);
            await getTranslations(pagination);
            await getVersion();
        }
        setExcelSubmitLoading(false);
    };

    useEffect(() => {
        refreshData();
    }, [selectedTags]);

    const refreshData = async () => {
        getTranslations(pagination);
        getLangs();
        getTags();
        getURLs();
        getVersion();
    };

    return (
        <div>
            <div
                style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginBottom: 10,
                    marginTop: 10,
                }}
            >
                <div>
                    <Button icon="reload" onClick={refreshData} loading={loading}>
                        v{version}
                    </Button>
                    <Search
                        placeholder="Search..."
                        allowClear
                        onChange={e => setSearch(e.target.value)}
                        value={search}
                        onSearch={search => getTranslations(pagination, { search }, null)}
                        style={{ width: 500 }}
                    />
                    <Select
                        mode="tags"
                        style={{ minWidth: 200, maxWidth: 600, marginLeft: 5 }}
                        placeholder="Select languages"
                        onChange={setSelectedLangs}
                        value={selectedLangs}
                    >
                        {langs.map(l => (
                            <Select.Option key={l.name}>{l.title}</Select.Option>
                        ))}
                    </Select>
                    <Select
                        mode="tags"
                        style={{ minWidth: 200, maxWidth: 600, marginLeft: 5 }}
                        allowClear
                        placeholder="Select tags"
                        onChange={setSelectedTags}
                        value={selectedTags}
                    >
                        {presetTags.map((l: any) => (
                            <Select.Option key={l.key}>{l.name}</Select.Option>
                        ))}
                    </Select>
                </div>
                <div>
                    <Button onClick={() => setImportDrawer(true)} icon="file-excel">
                        Import XLS
                    </Button>
                    <Button onClick={() => setManageDrawer(true)} type="primary" icon="plus">
                        Create
                    </Button>
                </div>
            </div>
            <Table
                dataSource={data}
                columns={columns}
                pagination={pagination}
                loading={loading}
                onChange={getTranslations}
            />
            <Drawer
                title={manageDrawerData?._id ? `Edit ${manageDrawerData?.key}` : "Create a new translation"}
                placement="right"
                onClose={() => {
                    setManageDrawer(false);
                    setManageDrawerData(dummyTRData);
                }}
                visible={manageDrawer}
                destroyOnClose
                width={650}
            >
                <ReactJson src={{ manageDrawerData }} collapsed />
                <b>Key</b>
                <Input
                    value={manageDrawerData?.key}
                    onChange={({ target: { value } }) => {
                        setManageDrawerData((prev: any) => ({ ...prev, key: value }));
                    }}
                />
                <Divider />
                <b>Description</b>
                <Input
                    value={manageDrawerData?.description}
                    onChange={({ target: { value } }) => {
                        setManageDrawerData((prev: any) => ({ ...prev, description: value }));
                    }}
                />
                <Divider />
                <b>Tags</b>
                <Select
                    mode="tags"
                    style={{ width: "100%" }}
                    allowClear
                    placeholder="Select tags"
                    onChange={(tags: string[]) => {
                        setManageDrawerData((prev: any) => ({ ...prev, tags }));
                    }}
                    value={manageDrawerData?.tags}
                >
                    {presetTags.map((l: any) => (
                        <Select.Option key={l.key}>{l.name}</Select.Option>
                    ))}
                </Select>
                <Divider />
                <b>URLs</b>
                <Select
                    mode="tags"
                    style={{ width: "100%" }}
                    allowClear
                    placeholder="Select or add URL(s)"
                    onChange={(url: string[]) => {
                        setManageDrawerData((prev: any) => ({ ...prev, url }));
                    }}
                    value={manageDrawerData?.url}
                >
                    {presetURLs.map((l: any) => (
                        <Select.Option key={l}>{l}</Select.Option>
                    ))}
                </Select>
                <Divider />
                <div style={{ marginBottom: 50 }}>
                    {_.map(langs, (l: any) => {
                        const isLocked = manageDrawerData?.locked_languages?.includes(l.name);
                        const canUnlock = sessionStore.user?.role.name === "owner";
                        const lock = () => {
                            setManageDrawerData((prev: any) => ({
                                ...prev,
                                locked_languages: _.uniq([...prev.locked_languages, l.name]),
                            }));
                        };
                        const unlock = () => {
                            setManageDrawerData((prev: any) => ({
                                ...prev,
                                locked_languages: _.without(prev.locked_languages, l.name),
                            }));
                        };
                        return (
                            <div key={l.name} style={{ marginBottom: 5 }}>
                                <div style={{ display: "flex", justifyContent: "space-between" }}>
                                    <b>{l.title}</b>
                                    {isLocked ? (
                                        canUnlock ? (
                                            <a onClick={unlock}>Unlock</a>
                                        ) : (
                                            <span>Locked</span>
                                        )
                                    ) : (
                                        <a onClick={lock}>Lock</a>
                                    )}
                                    {/* {!isLocked ? <a>Lock</a> : null} */}
                                </div>

                                <TextArea
                                    rows={2}
                                    readOnly={isLocked}
                                    value={manageDrawerData.langs[l.name]}
                                    style={{ background: isLocked ? "#f5f5f5" : "white" }}
                                    onChange={({ target: { value } }) => {
                                        setManageDrawerData((prev: any) => ({
                                            ...prev,
                                            langs: {
                                                ...prev.langs,
                                                [l.name]: value,
                                            },
                                        }));
                                    }}
                                />
                            </div>
                        );
                    })}
                </div>
                <div
                    style={{
                        position: "absolute",
                        right: 0,
                        bottom: 0,
                        width: "100%",
                        borderTop: "1px solid #e9e9e9",
                        padding: "10px 16px",
                        background: "#fff",
                        textAlign: "right",
                    }}
                >
                    <Checkbox
                        checked={useAi}
                        onChange={e => {
                            setUseAi(e.target.checked);
                        }}
                        style={{ marginRight: 5 }}
                    >
                        Use AI
                    </Checkbox>

                    <Button loading={manageSubmitLoading} onClick={submitManageData} type="primary">
                        Submit
                    </Button>
                </div>
            </Drawer>

            <ExcelDrawer
                langs={langs}
                setImportDrawer={setImportDrawer}
                importDrawer={importDrawer}
                submitExcelTranslations={submitExcelTranslations}
                loading={excelSubmitLoading}
            />

            <Modal
                title={"Delete"}
                visible={!_.isNull(deleteModal)}
                onOk={deleteTranslation}
                onCancel={() => {
                    setDeleteModal(null);
                    setDeletePhraseModal("");
                }}
                destroyOnClose
                okButtonProps={{ loading: deleteLoading, disabled: deletePhraseModal !== deleteModal?.key }}
            >
                <p>
                    To delete, write down: <b>{deleteModal?.key}</b>
                </p>
                <Input
                    value={deletePhraseModal}
                    onChange={e => setDeletePhraseModal(e.target.value)}
                    style={{ color: "red", fontWeight: "bold" }}
                />
            </Modal>
            <ReactJson src={{ data, pagination, search, langs, manageDrawerData, selectedTags }} collapsed />
        </div>
    );
};

export default TranslatePage;
