import { BrandsPage } from "@app/pages/brands/BrandsPage/BrandsPage";
import { ClientsPage } from "@app/pages/clients/ClientsPage/ClientsPage";
import { UsersPage } from "@app/pages/users/UsersPage/UsersPage";
import { RouteComponentProps } from "react-router-dom";
import { BrandTerminalsPage } from "@app/pages/brands/BrandsPage/BrandTerminalsPage/BrandTerminalsPage";
import { PackagesPage } from "@app/pages/packages/PackagesPage/PackagesPage";
import { TransactionsPage } from "@pages/transactions/TransactionsPage/TransactionsPage";
import * as React from "react";
import { AbsentsPage } from "@app/pages/absents/AbsentsPage";
import StoriesPage from "@app/pages/stories/StoriesPage";
import { PartnerPage } from "@app/pages/partners/PartnerPage/PartnersPage";
import TranslatePage from "@app/pages/translations/TranslatePage";
import { DashboardPage } from "@app/pages/dashboard-new/DashboardPage";
import { VenuesPage } from "@app/pages/venues/VenuesPage/VenuesPage";
import { CloposPayTransactionsPage } from "@pages/transactions/TransactionsPage/CloposPayTransactionsPage";
import MaintenanceModePage from "@pages/maintenance/MaintenanceModePage";
import { TerminalsPageNew } from "@app/pages/terminals/TerminalsPage/TerminalsPageNew";
import { DbConfigPage } from "@app/pages/dbconfigs/page/page";
import { TagsPage } from "@pages/tags/page/page";
import { BrandDataHistoryPage } from "@pages/logs/BrandDataHistoryPage/HistoryPage";
import { DeliveryServiceHistoryPage } from "@pages/logs/DeliveryServiceHistoryPage";
import { DownloadsPage } from "@app/pages/downloads/page/DownloadsPage";

export interface IRoute {
    path: string;
    main: React.ComponentType<RouteComponentProps<any>> | React.ComponentType<any>;
    exact?: boolean;
    permission: string[];
}

const exact = true;

export const routes: IRoute[] = [
    { path: "/", main: DashboardPage, exact, permission: ["admin", "owner"] },
    { path: "/users", main: UsersPage, permission: ["admin", "owner"] },
    { path: "/brands", main: BrandsPage, exact, permission: ["admin", "owner", "seller", "moderator"] },
    { path: "/brand/:brandId/terminals", main: BrandTerminalsPage, exact, permission: ["admin", "owner"] },
    { path: "/venues", main: VenuesPage, exact, permission: ["owner"] },
    // { path: "/terminals", main: TerminalsPage, permission: ["admin", "owner"] },
    { path: "/terminals", main: TerminalsPageNew, permission: ["admin", "owner"] },
    { path: "/clients", main: ClientsPage, permission: ["admin", "owner", "seller", "moderator"] },
    { path: "/stories", main: StoriesPage, permission: ["admin", "owner", "moderator"] },
    { path: "/packages", main: PackagesPage, permission: ["admin", "owner"] },
    { path: "/transactions", main: TransactionsPage, permission: ["admin", "owner"] },
    { path: "/clopos-pay-transactions", main: CloposPayTransactionsPage, permission: ["admin", "owner"] },
    { path: "/tools/maintenance-mode", main: MaintenanceModePage, permission: ["admin", "owner"] },
    { path: "/translations", main: TranslatePage, permission: ["admin", "owner", "translator"] },
    { path: "/partners", exact: true, main: PartnerPage, permission: ["admin", "owner"] },
    { path: "/absents", main: AbsentsPage, permission: ["admin", "owner", "seller", "moderator"] },
    { path: "/tools/db-configs", main: DbConfigPage, permission: ["admin"] },
    { path: "/tools/downloads", main: DownloadsPage, permission: ["admin", "owner", "seller", "moderator"] },
    { path: "/tags", main: TagsPage, permission: ["owner"] },
    { path: "/logs/brand", main: BrandDataHistoryPage, permission: ["owner"] },
    { path: "/logs/delivery-service", main: DeliveryServiceHistoryPage, permission: ["owner"] },
];
