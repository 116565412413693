import { ajax } from "@app/lib/ajax";
import React, { useEffect, useState } from "react";
import { parse } from "date-fns";
import { Table } from "antd";

interface IItem {
    filename: string;
    version: string;
    size: string;
    hash: string;
    create_date: string;
    download_url: string;
}

export const DownloadsPage = () => {
    const [list, setList] = useState<IItem[]>([]);
    const [lastPublishedVersion, setLastPublishedVersion] = useState<string>("");
    const columns = [
        {
            title: "Filename",
            dataIndex: "filename",
            key: "filename",
        },
        {
            title: "Version",
            dataIndex: "version",
            key: "version",
        },
        {
            title: "Size",
            dataIndex: "size",
            key: "size",
        },
        {
            title: "Create Date",
            dataIndex: "create_date",
            key: "create_date",
        },
        {
            title: "Download",
            dataIndex: "download_url",
            key: "download_url",
            render: (text: string) => (
                <a href={text} target="_blank" rel="noreferrer">
                    Download
                </a>
            ),
        },
        {
            title: "Fast Link",
            dataIndex: "download_url",
            key: "fast_link",
            render: (text: string) => <a href={text.replace("https://", "https://r2-")}>Fast link</a>,
        },
        {
            title: "Latest",
            dataIndex: "version",
            key: "latest",
            render: (text: string) => (text === lastPublishedVersion ? "(latest)" : ""),
        },
    ];
    useEffect(() => {
        ajax.get({ url: "https://automation.clopos.com/storage/pos/exe", ignoreSuccess: true }).then((res: any) => {
            console.log("Got data", res);
            setList(
                (res as IItem[]).sort((a, b) => {
                    // create date from format "2021-09-29" using date-fns
                    const da = parse(a.create_date, "yyyy-MM-dd", new Date());
                    const db = parse(b.create_date, "yyyy-MM-dd", new Date());

                    return db.getTime() - da.getTime();
                }),
            );
        });
    }, []);

    useEffect(() => {
        fetch("https://downloads.clopos.com/pos/windows/latest.yml")
            .then(res => res.text())
            .then(res => {
                const version = res.split("\n")[0].split(":")[1].trim();
                setLastPublishedVersion(version);
            })
            .catch(err => {
                console.error("Failed to get latest version info", err);
            });
    }, []);

    return (
        <div>
            <h1>Downloads</h1>
            <div>
                <Table bordered dataSource={list} columns={columns} rowKey="filename" />
            </div>
        </div>
    );
};
