import { endpoints } from "@app/config/endpoints";
import { ajax } from "@app/lib/ajax";
import { useEffect, useState, useCallback } from "react";

export function usePartners() {
    const [partners, setPartners] = useState<IPartner[]>([]);

    useEffect(() => {
        loadPartners().then(setPartners);
    }, []);

    const reloadPartners = useCallback(() => {
        loadPartners().then(setPartners);
    }, []);

    return { partners, reloadPartners };
}

async function loadPartners() {
    const resp = await ajax.get({
        url: endpoints.partners,
        params: {
            limit: 999,
        }
    });
    const data: IPartner[] = [];

    resp.data
        .filter((p: IPartner) => !p.parent_id)
        .forEach((partner: IPartner) => {
            data.push(partner);
            resp.data
                .filter((p: IPartner) => p.parent_id === partner.id)
                .forEach((child: IPartner) => {
                    child.parent = partner;
                    data.push(child);
                });
        });
    return data;
}
